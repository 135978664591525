import { useContext, useLayoutEffect } from 'react'
import { context } from '~/layouts'
import { scroller } from 'react-scroll'

const useScroll = () => {
  const { setLoading } = useContext(context)

  useLayoutEffect(() => {
    if (window.location.hash) {
      const targetEl: HTMLElement = document.querySelector(window.location.hash)
      if (targetEl) {
        setLoading(true)
        const f = setInterval(() => {
          const scrollY = targetEl.offsetTop
          const viewsCount = (document.querySelector('.views') as HTMLElement)
            .childElementCount
          if (viewsCount === 1 && document.body.offsetHeight >= scrollY) {
            scroller.scrollTo(window.location.hash.split('#')[1], {
              offset: -0.65 * document.getElementById('menu-bar').offsetHeight,
            })
            setLoading(false)
            clearInterval(f)
          }
        }, 50)
      }
    }
  }, [])
}

export default useScroll
