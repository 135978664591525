import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'
import {
  Head,
  Video,
  Lazyload,
  Plants,
  Tabs,
  Gallery,
  Location,
  Form,
  Button,
  Link,
  Footer,
} from '~/components'
// import parse from 'html-react-parser'
import nl2br from 'react-nl2br'
import { scroller } from 'react-scroll'
import cn from 'classnames'
import * as st from '~/assets/styl/Enterprise.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'
import * as st2 from '~/assets/styl/Certifications.module.styl'
import * as st3 from '~/assets/styl/About.module.styl'

const Header = styled.header<{ image: string }>`
  ${(props) =>
    props.image &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0,
          rgba(0, 0, 0, 0.2) 100%
        ),
        url(${props.image}) center / cover;
    `}
`

interface Stage {
  name: string
  value: string
}

const Bar = styled.li<{ value: string }>`
  min-height: 2px;
  ${(props) =>
    css`
      height: ${props.value}%;
    `}
`

type Zoom = Array<{
  miniature: string
  zoom: string
  legend: string
}>

interface EnterpriseDetails {
  id: string
  slug: string
  name: string
  address: string
  district: string
  city: string
  cover: string
  completePlan: string
  state: {
    name: string
    acronym: string
  }
  cep: string
  latitude: string
  longitude: string
  category?: {
    name: string
  }
  introduction: {
    title: string
    text: string
    video: string
    image: string
  }
  about: {
    title: string
    text: string
    areaTotal: string
    paving: string
    image: string
    unity: string
    unityTitle: string
  }
  features: Array<{
    name: string
    characteristic: string
    image: string
  }>
  plants: Array<{
    category: {
      name: string
    }
    name: string
    image: string
  }>
  stages: Array<{
    stage: {
      name: string
    }
    percentComplete: string
  }>
  galleryFacade: Zoom
  galleryCommonArea: Zoom
  galleryApartment: Zoom
  galleryVideos: Array<string>
  galleryVideosLegends: Array<string>
  galleryTours: Array<string>
  galleryStages: Zoom
  stageVideos: Array<string>
  stageVideosLegends: Array<string>
}

const Enterprise = ({
  location,
  pageContext,
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const { result: enterprise }: { result: EnterpriseDetails } = useApi(
    {
      result: pageContext,
    },
    'constructions/' + (slug || (pageContext as EnterpriseDetails).slug)
  )

  const { setLoading } = useContext()
  useEffect(() => {
    setLoading(!enterprise?.name)
  }, [enterprise])

  const features = [
    (enterprise.features || []).filter(
      (i) => i.characteristic === 'Empreendimento'
    ),
    (enterprise.features || []).filter(
      (i) => i.characteristic === 'Apartamento'
    ),
  ]
  const Features =
    features[0].length && features[1].length
      ? ({ children }) => (
          <Tabs
            pages={[
              'Características do empreendimento',
              'Características do apartamento',
            ]}
            classes={{ selector: st.tabs }}
          >
            {children}
          </Tabs>
        )
      : ({ children }) => <>{children}</>

  const stages: Array<Stage> = (enterprise.stages || []).map(
    ({ stage, percentComplete }) => ({
      name: stage.name,
      value: percentComplete,
    })
  )

  const unities = (enterprise.about?.unity || '').split('\n')

  return enterprise?.name ? (
    <>
      <Head
        title={`${enterprise.name} - ${process.env.GATSBY_SITE_NAME}`}
        location={location}
      />

      <Header image={enterprise.cover} className={st.header}>
        <div>
          <span className={st1.pretitle}>
            {enterprise.category ? enterprise.category.name + ' - ' : ''}
            {enterprise.district} - {enterprise.city}
          </span>
          <h1 className={st1.title}>{enterprise.name}</h1>
        </div>
      </Header>

      {enterprise.introduction?.title && enterprise.introduction?.text && (
        <section className={st.videoSection}>
          <div className={st.content}>
            <h2>{nl2br(enterprise.introduction.title)}</h2>
            <p>{nl2br(enterprise.introduction.text)}</p>
          </div>
          {enterprise.introduction.video && (
            <Video
              src={enterprise.introduction.video}
              thumbnail={enterprise.introduction.image}
              className={st.video}
            />
          )}
        </section>
      )}

      {enterprise.about && (
        <section className={st.description}>
          <div className={st.container}>
            <div className={st.text}>
              <h2>{nl2br(enterprise.about.title)}</h2>
              <p>{nl2br(enterprise.about.text)}</p>
              {(enterprise.category?.name === 'Lançamento' ||
                enterprise.completePlan) && (
                <div className={st.tags}>
                  {enterprise.category.name === 'Lançamento' && (
                    <div className={st.releaseCard}>
                      <strong>Lançamento</strong>
                      <span>
                        Garantia de entrega alto padrão de qualidade 100%
                        clientes satisfeitos
                      </span>
                    </div>
                  )}
                  {enterprise.completePlan && (
                    <div>
                      <strong>{enterprise.completePlan}</strong>
                    </div>
                  )}
                </div>
              )}
              <ul className={st.topics}>
                {enterprise.about.areaTotal && (
                  <li className={st.area}>
                    <strong>Área total: {enterprise.about.areaTotal}</strong>
                  </li>
                )}
                {enterprise.about.paving && (
                  <li className={st.floors}>
                    <strong>{enterprise.about.paving}</strong>
                  </li>
                )}
                {Boolean(unities.length) && (
                  <li className={st.key}>
                    <strong>{enterprise.about.unityTitle}</strong>
                    <ul>
                      {unities.map((i, key) => (
                        <li key={key}>{i}</li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            </div>
            <Lazyload src={enterprise.about.image} className={st.image} />
          </div>
        </section>
      )}

      {Boolean(features[0].length || features[1].length) && (
        <section className={st.pattern}>
          <div className={st.container}>
            <h2>Padrão construtivo</h2>
            <Features>
              {Boolean(features[0].length) && (
                <ul>
                  {features[0].map(({ image, name }, key) => (
                    <li key={key}>
                      <img src={image} width="86" height="86" />
                      {name}
                    </li>
                  ))}
                </ul>
              )}
              {Boolean(features[1].length) && (
                <ul>
                  {features[1].map(({ image, name }, key) => (
                    <li key={key}>
                      <img src={image} width="86" height="86" />
                      {name}
                    </li>
                  ))}
                </ul>
              )}
            </Features>
            <div className={st.btn}>
              <Button
                onClick={() =>
                  scroller.scrollTo('SoliciteInformacoes', {
                    smooth: true,
                    duration: 500,
                    offset:
                      -0.65 * document.getElementById('menu-bar').offsetHeight,
                  })
                }
              >
                Solicite informações
              </Button>
            </div>
          </div>
        </section>
      )}

      {Boolean(
        enterprise.galleryFacade?.length ||
          enterprise.galleryCommonArea?.length ||
          enterprise.galleryApartment?.length ||
          enterprise.galleryVideos?.length ||
          enterprise.galleryTours?.length
      ) && (
        <section className={st.gallery}>
          <div className={st.container}>
            <h2>Galeria</h2>
            <Tabs
              pages={[
                enterprise.galleryFacade?.length ? 'Fachada' : '',
                enterprise.galleryCommonArea?.length ? 'Áreas comuns' : '',
                enterprise.galleryApartment?.length ? 'Apartamentos' : '',
                enterprise.galleryVideos?.length ? 'Vídeos' : '',
                enterprise.galleryTours?.length ? 'Tour 360º' : '',
              ]}
              classes={{ selector: st.tabs }}
            >
              {Boolean(enterprise.galleryFacade?.length) && (
                <div>
                  <Gallery
                    items={enterprise.galleryFacade.map(
                      ({ miniature: url, zoom, legend }) => ({
                        url,
                        zoom,
                        legend,
                      })
                    )}
                  />
                </div>
              )}
              {Boolean(enterprise.galleryCommonArea?.length) && (
                <div>
                  <Gallery
                    items={enterprise.galleryCommonArea.map(
                      ({ miniature: url, zoom, legend }) => ({
                        url,
                        zoom,
                        legend,
                      })
                    )}
                  />
                </div>
              )}
              {Boolean(enterprise.galleryApartment?.length) && (
                <div>
                  <Gallery
                    items={enterprise.galleryApartment.map(
                      ({ miniature: url, zoom, legend }) => ({
                        url,
                        zoom,
                        legend,
                      })
                    )}
                  />
                </div>
              )}
              {Boolean(enterprise.galleryVideos?.length) && (
                <div>
                  <Gallery
                    type="videos"
                    items={enterprise.galleryVideos.map((url, index) => ({
                      url,
                      legend: (enterprise.galleryVideosLegends || [])[index],
                    }))}
                  />
                </div>
              )}
              {Boolean(enterprise.galleryTours?.length) && (
                <div>
                  <div className={st.iframe}>
                    <iframe src={enterprise.galleryTours[0]}></iframe>
                  </div>
                </div>
              )}
            </Tabs>
          </div>
        </section>
      )}

      <Plants plants={enterprise.plants} />

      {Boolean(
        stages.length ||
          enterprise.galleryStages?.length ||
          enterprise.stageVideos?.length
      ) && (
        <section className={st.work}>
          <div className={st.container}>
            <h2>Acompanhe a obra</h2>
            <Tabs
              pages={[
                stages.length ? 'Status da obra' : '',
                enterprise.galleryStages?.length ? 'Fotos' : '',
                enterprise.stageVideos?.length ? 'Vídeos' : '',
              ]}
              classes={{ selector: st.tabs }}
            >
              {Boolean(stages.length) && (
                <div className={st.chart}>
                  <ul className={st3.chart}>
                    {stages.map(({ name, value }, key) => (
                      <Bar value={value} className={st.bar} key={key}>
                        <h3>{name}</h3>
                        <p>{value}</p>
                      </Bar>
                    ))}
                  </ul>
                </div>
              )}
              {Boolean(enterprise.galleryStages?.length) && (
                <div>
                  <Gallery
                    items={enterprise.galleryStages.map(
                      ({ miniature: url, zoom, legend }) => ({
                        url,
                        zoom,
                        legend,
                      })
                    )}
                  />
                </div>
              )}
              {Boolean(enterprise.stageVideos?.length) && (
                <div>
                  <Gallery
                    type="videos"
                    items={enterprise.stageVideos.map((url, index) => ({
                      url,
                      legend: enterprise.stageVideosLegends[index],
                    }))}
                  />
                </div>
              )}
            </Tabs>
          </div>
        </section>
      )}

      <Location
        location={
          enterprise.city +
          (enterprise.state ? ' - ' + enterprise.state.acronym : '')
        }
        latitude={enterprise.latitude}
        longitude={enterprise.longitude}
      >
        {enterprise.address}
        {(enterprise.state || enterprise.district) && <br />}
        {enterprise.district
          ? enterprise.district + (enterprise.state ? ', ' : '')
          : ''}
        {enterprise.state && enterprise.state.name}
        {enterprise.cep && (
          <>
            <br />
            CEP:{' '}
            {enterprise.cep
              .toString()
              .replace(/\D/g, '')
              .replace(/(\d{5})(\d)/, '$1-$2')
              .replace(/(-\d{3})\d+?$/, '$1')}
          </>
        )}
      </Location>

      <section className={st.form} id="SoliciteInformacoes">
        <div className={st.content}>
          <h2>Solicite mais informações</h2>
          <p>
            Entre em contato com nosso time de consultores para obter mais
            informações.
          </p>
          <Form
            path="more-information"
            idPrefix="contact"
            button={<Button>Enviar mensagem</Button>}
            loadingButton={<Button>Enviando...</Button>}
            hiddenInputs={{
              construction: enterprise.id,
            }}
            inputs={[
              {
                name: 'name',
                label: 'Nome*',
              },
              {
                name: 'email',
                label: 'E-mail*',
                type: 'email',
              },
              {
                name: 'whatsapp',
                label: 'WhatsApp*',
                mask: 'phone',
              },
              {
                name: 'contactInterest',
                label: 'Interesse do contato*',
              },
              {
                name: 'message',
                label: 'Mensagem*',
                type: 'textarea',
                className: 'wide',
              },
              {
                name: 'privacy',
                label: (
                  <>
                    Declaro que li e aceito a{' '}
                    <Link href="/politica-de-privacidade/">
                      Política de Privacidade
                    </Link>
                  </>
                ),
                type: 'checkbox',
                className: 'wide',
              },
            ]}
          />
        </div>
        <div className={cn(st2.image, st.image)}></div>
      </section>

      <Footer />
    </>
  ) : (
    <></>
  )
}

export default Enterprise
